import charlie1 from "../../images/teampics/Charlie.jpg";
import cody1 from "../../images/teampics/Cody.jpg";
import dash1 from "../../images/teampics/Dash.jpg";
import hank1 from "../../images/teampics/Hank.jpg";
// import lily1 from "../../images/teampics/Lily_retired.jpg";
import logan1 from "../../images/teampics/Logan.jpg";
import luke1 from "../../images/teampics/Luke.jpg";
// import marlee1 from "../../images/teampics/Marlee_retired.jpg";
// import sparky1 from "../../images/teampics/Sparky_retired.jpg";
import toby1 from "../../images/teampics/Toby_retired.jpg";
import gypsy1 from "../../images/teampics/Gypsy.jpg";
import indy1 from "../../images/teampics/Indy.jpg";
// import sprocket1 from "../../images/teampics/Sprocket.jpg";
import mrfurrley1 from "../../images/teampics/MrFurrley.jpg";
import julius1 from "../../images/teampics/Julius.jpg";
import umka1 from "../../images/teampics/Umka.jpg";
import winnie from "../../images/teampics/Winnie.jpg";
import ori1 from "../../images/teampics/Ori.jpg";


// Tools:
// https://bulkresizephotos.com/en
// https://heictojpg.com/
// 480 x 640 Dimensions

const imageData = [
  {
    original: charlie1,
    thumbnail: charlie1,
    description: "Charlie"
  },
  {
    original: winnie,
    thumbnail: winnie,
    description: "Winnie"
  },
  {
    original: cody1,
    thumbnail: cody1,
    description: "Cody"
  },
  {
    original: dash1,
    thumbnail: dash1,
    description: "Dash"
  },
  {
    original: hank1,
    thumbnail: hank1,
    description: "Hank"
  },
  // {
  //   original: lily1,
  //   thumbnail: lily1,
  //   description: "Lily (Retired)"
  // },
  {
    original: logan1,
    thumbnail: logan1,
    description: "Logan"
  },
  {
    original: luke1,
    thumbnail: luke1,
    description: "Luke"
  },
  // {
  //   original: marlee1,
  //   thumbnail: marlee1,
  //   description: "Marlee (Retired)"
  // },
  // {
  //   original: sparky1,
  //   thumbnail: sparky1,
  //   description: "Sparky (Retired)"
  // },
  {
    original: toby1,
    thumbnail: toby1,
    description: "Toby (Retired)"
  },
  {
    original: gypsy1,
    thumbnail: gypsy1,
    description: "Gypsy"
  },
  {
    original: indy1,
    thumbnail: indy1,
    description: "Indy"
  },
  // {
  //   original: sprocket1,
  //   thumbnail: sprocket1,
  //   description: "Sprocket"
  // },
  {
    original: mrfurrley1,
    thumbnail: mrfurrley1,
    description: "Mr Furrley"
  },
  {
    original: julius1,
    thumbnail: julius1,
    description: "Julius"
  },
  {
    original: umka1,
    thumbnail: umka1,
    description: "Umka"
  },
  {
    original: ori1,
    thumnail: ori1,
    description: "Ori"
  }
];

export default imageData;
